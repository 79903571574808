@function breakpoint-parse-default($feature) {
  $default: $breakpoint-default-feature;

  // Set Context
  $context-setter: private-breakpoint-set-context($default, $feature);

  @if ($breakpoint-to-ems == true) and (type-of($feature) == 'number') {
    @return '#{$default}: #{breakpoint-to-base-em($feature)}';
  }
  @else {
    @return '#{$default}: #{$feature}';
  }
}
