
.front-page .banner.top {
    height:750px;
    background:#fff;
    position: relative;
    overflow: hidden;
    height: 1015px;
    @include brk($t) { height:725px }
    @include brk($m) { height:525px }
    >.image {
        background-size:cover;
        background-position:50% 50%;
    }

    .content {
        overflow: visible;
    }

    .image-label {
        width: 390px;
        height: 390px;
        position: absolute;
        right: 10%;
        top: 305px;
        background-position: 50% 50%;
        background-size: contain;
        @include brk($t) {
            width: 350px;
            height: 350px;
            top: 180px;
            right: 6%;
        }
        @include brk($td) {
            width: 330px;
            height: 330px;
            top: 210px;
            right: 25px;
        }
        @include brk($tp) {
            width: 260px;
            height: 260px;
            top: 350px;
            right: 50%;
            transform: translateX(50%);
        }
        @include brk($m) {
            width: 160px;
            height: 160px;
            top: 230px;
        }
    }

    

    .bloc-container {
        margin-top: $header-height;
        height:450px;
        line-height: 450px;
        @include brk($t) { 
            height:auto;
            margin-top: 100px; 
            line-height: 1;
        }
        @include brk($m) { 
            margin-top: 60px; }
        .bloc {
            padding-top:180px;
            animation: fade 1s;
            width: 50%;
            @include brk($td) {
                padding-top: 160px;
                width: 56%;
            }
            @include brk($tp) {
                text-align: center;
                width: 100%;
                padding-top: 90px;
            }
            @include brk($m) {
                padding-top: 70px;
            }
        }
        .titre {
            z-index: 80;
            position: relative;
            color:#095c80;
            font-size: 68px;
            font-weight: $light;
            line-height: 1;
            margin-bottom: 25px;
            animation: fade 1s;
            letter-spacing: 0;
            @include brk($t) {
                font-size: 58px;
                line-height: 1.1;
                br {display:inline}
            }
            @include brk($td) {
                font-size: 56px;
            }
            @include brk($tp) {
                font-size: 58px;
            }
            @include brk($m) {
                font-size: 32px;
            }
        }
        .sous-titre {
            @include brk($td) {
                br {display: none !important;}
            }
            @include brk($tp) {
                position: absolute;
                bottom: 30px;
                left:50%;
                transform: translate(-50%,0);
                width: 100%;
            }
            p {
                position: relative;
                z-index: 80;
                color:#095c80;
                font-size: 21px;
                font-weight: $light;
                animation: fade 2s;
                @include brk($m) {
                    font-size: 13.5px;
                }
            }
        }
    }
   
    .gradient {
        position: absolute;
        width:100%;
        left:0;
        right:0;
        bottom:0;
        height:60px;
        z-index: 80;
        background: linear-gradient(to bottom, 
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0.07) 50%,
            rgba(0,0,0,0.2) 100%
            );
    }
    .gradient-blue {
        position: absolute;
        width:100%;
        left:0;
        right:0;
        bottom:0;
        height:250px;
        background: linear-gradient(to bottom, 
            transparentize(#081640,1) 0%, 
            transparentize(#081640,0.3) 50%,
            #081640 100%
        );
        @include brk($m) {
            height:250px;
        }
    }
}














.banner.etapes {
    .banner-content .content {
        >.sur-titre {
            font-size: 11px;
            color:#1599d6;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin-bottom: 5px;
            @include brk($m) { 
                margin-bottom: 10px;
            }
        }
        >.titre {
            font-size: 35px;
            color:#104b99;
            font-weight: $black;
            position: relative;
            margin-bottom: 70px;
            &:before {
                content:"";
                width:57px;
                height:1px;
                display: inline-block;
                position: absolute;
                left:-90px;
                top:32px;
                background:#1599d6;
                @include brk($l) {
                    display: none;
                }
            }
            @include brk($t) {
                margin-bottom: 40px;
            }
            @include brk($m) { 
                font-size: 27.5px;
                line-height: 1.2;
            }
        }
        @include brk($t) {
            // text-align: center;
        }
    }
    
}











.banner.chiffres {
    background:$touche1;
    padding: 80px 0 20px;
    text-align:center;
    @include brk($tp) {
        padding: 70px 0 50px; 
    }
    @include brk($m) {
        padding: 60px 0 40px;
    }

    .titres-section {
        margin-bottom:50px;
        @include brk($tp) {
            margin-bottom:30px;
        }
        .sur-titre {
            color:#fff;
            font-size:11px;
            font-weight:$bold;
            text-transform:uppercase;
            letter-spacing:4px;
        }
        .titre {
            color:#fff;
            font-size:45px;
            font-weight: $black;
            @include brk($tp) {
                font-size:40px;
            }
            @include brk($m) {
                font-size:35px;
            }
        }
    }
    ul {
        width: calc(100% + 20px);
        li {
            width: 33.33%;
            padding-right:20px;
            display:inline-block;
            float:left;
            position: relative;
            @for $i from 1 through 10 {
                &:nth-child(#{$i})  { 
                    animation-delay: ((0.1s * $i) - 0.05s);
                }
            }
            @include brk($m) {
                width: 100%;
            }
            &:nth-child(2) .inside {
                &::before, &::after {
                    content:"";
                    position: absolute;
                    top:0;
                    height:100%;
                    width:40px;
                    background-image:url('../assets/images/ombre-side.png');
                    background-size:contain;
                    @include brk($tp) {
                        display:none;
                    }
                }
                &::before {
                    left:-40px;
                    background-position:100% 50%;
                }
                &::after {
                    transform: rotate(180deg);
                    right:-40px;
                    background-position:100% 50%;
                }
            }
            a.inside {
                padding: 20px 10% 100px;
                cursor:default;
                @include brk($tp) {
                    padding: 20px 10% 30px;
                }
                &[href]:not([href=""]):hover {
                    .texte p {text-decoration: underline;}
                    cursor:pointer;
                };
                .titre {
                    color:#fff;
                    font-size:60px;
                    font-weight:$light;
                    @include brk($tp) {
                        font-size:50px;
                    }
                    @include brk($m) {
                        font-size:40px;
                    }
                }
                .texte {
                    p {
                        color:#fff;
                        font-size:17px;
                        @include brk($m) {
                            font-size:15px;
                        }
                    }
                }
            }
        }
    }
}




.banner.references {
    background:#fff;
    text-align:center;
    padding: 80px 0 100px;
    .titres-section {
        margin-bottom:60px;
        .titre {
            font-weight:$bold;
            color:#104b99;
            font-size:32.5px;
            line-height:1.2;
            @include brk($m) {
                font-size:25px;
            }
        }
    }
    ul {
        width: 100%;
        display:inline-block;
        margin-bottom:120px;
        li {
            width: 25%;
            display:inline-block;
            float:left;
            @for $i from 1 through 10 {
                &:nth-child(#{$i})  { 
                    animation-delay: ((0.1s * $i) - 0.05s);
                }
            }
            @include brk($m) {
                width: 50%;
            }
            a {
                height:200px;
                line-height:200px;
                border-top:1px solid transparentize(#000, 0.9);
                border-right:1px solid transparentize(#000, 0.9);
                // filter: saturate(0%);
                // opacity:0.6;
                cursor:default;
                @include brk($tp) {
                    height:150px;
                    line-height:150px;
                }
                img {
                    width: 65%;
                    height:auto;
                }
                &.has-href:hover {
                    opacity:1;
                    cursor:pointer;
                    filter: saturate(100%);
                }
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                a {border-top:none;}
            }
            &:nth-child(4n) a, &:last-child a {
                border-right:none;
            }
            @include brk($m) {
                &:nth-child(2n) a {
                    border-right:none;
                }
                &:nth-child(3n) a, &:nth-child(4n) a {
                    border-top:1px solid transparentize(#000, 0.9);
                }
            }
        }
    }
    .texte-section {
        position: relative;
        padding: 10px 0 15px;
        .ombre {
            height:20px;
            width: 80%;
            position: absolute;
            top:-50px;
            left:10%;
            background-position:50% 100%;
            background-size:contain;
            animation: fade 1s;
            &.bottom {
                top:auto;
                bottom:-50px;
                transform: rotate(180deg);
            }
        }
        .titre {
            color:#89c13e;
            font-weight:$bold;
            font-size:30px;
            line-height:1.4;
            margin-bottom: 25px;
            @include brk($m) {
                font-size:23px;
                padding:0 6%;
            }
        }
        .texte {
            p {
                font-size: 15px;
                line-height: 1.7;
            }
        }
    }
}