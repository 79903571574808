





.post.list {
	background:#fff;
	animation: fade 1s;
}




.post.list .banner.top {
	background:#125c80;
	height:520px;
	// padding-top:50px;
	// animation: fade 1s;
	transition:height 1s;
	margin-top:$header-height;
	@include brk($t) {
		margin-top:$header-height-tablet;
		height:482px;
	}
	@include brk($m) {
		height:236px;
	}
	.banner-content {
		@include brk($t) {
			padding:0;
		}
	}
	.content {
		height:100%;
		position: relative;
	}
	.focus-container {
		background: #fff;
		width:50%;
		position: absolute;
		bottom:-40px;
		right:0;
		height:350px;
		z-index: 100;
		@include brk($t) {
			width:80%;
		}
		@include brk($m) {
			width:90%;
			height:auto;
			bottom:-130px;
		}

		.inside {
			padding:40px 60px;
			@include brk($m) {
				padding:30px 25px 100px;
			}
			.focus {
				animation: fade 1s;
				font-size: 9.5px;
				color:#a1a1a1;
				text-transform: uppercase;
				font-weight: $semi-bold;
				letter-spacing: 1.5px;
				margin-bottom: 40px;
				@include brk($m) {
					margin-bottom: 12px;
				}
			}
			.titre {
				animation: fade 1s;
				color:$touche_de_couleur2;
				font-weight: $semi-bold;
				font-size: 25px;
				line-height: 1.4;
				@include brk($m) {
					font-size: 17.5px;
				}
			}
			.lire-la-suite {
				position: absolute;
				bottom:50px;
				left:60px;

				@include brk($m) {
					left:25px;
					bottom:35px;
				}
				p {
					animation: fade 1s;
					color:$touche_de_couleur2;
					font-size:13px;
					font-weight: $bold;
				}
				&:after {
					content:"";
					display: inline-block;
					height:2px;
					width:0;
					background:$touche_de_couleur;
					position: absolute;
					bottom:0;
					left:0;
					opacity: 0;
					transition: width 0.1s, opacity 0.8s;
				}
			}
			&:after {
				content:"";
				display: inline-block;
				width:1000%;
				height:100%;
				position: absolute;
				left:100%;
				top:0;
				background:$touche1;
			}
		}
		&:hover {
			.lire-la-suite {
				&:after {
					opacity: 1;
					width:93%;
				}
			}
		}
		
	}
}



.post.list .banner.medium {
	margin-top:-5px;
	background:#f4f4f4;
	padding:115px 0 90px;
	z-index:80;

	@include brk($t) {
		padding:110px 0 90px;
	}
	@include brk($m) {
		padding:200px 0 90px;
	}
	.content {
		position: relative;
	}
	.titre-container {
		margin-bottom: 68px;
		.icon {
			position: absolute;
			width: 80px;
			height: 80px;
			top:-20px;
			left:0;
			.st0{opacity:0.15;fill:#1499D6;}
			.st1{fill:#1499D6;}
			.st2{fill:none;stroke:#FFFFFF;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
		}
		.titre {
			color: $touche_de_couleur2;
			font-size: 22.5px;
			font-weight: $bold;
			padding-left: 107px;
		}
	}

	ul {
		width: 100%;
		li {
			height:250px;
			width:48.1%;
			float: left;
			background:#fff;
			margin-bottom: 20px;
			box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.15);
            transition: box-shadow 0.2s;
			@include brk($t) {
				width:100%;
				float: none!important;
			}
			@include brk($m) {
				height:auto;
			}
			&:nth-child(2n) {float:right;}

			.image-container {
				width:45%;
				position: relative;
				height:100%;
				float: left;
				@include brk($m) {
					height:250px;
					width:100%;
					float: none;
				}
				.image {
					animation: none;
				}
			}
			.textes-container {
				width:55%;
				height:100%;
				float: right;
				padding: 30px 30px 25px;
				position: relative;
				@include brk($m) {
					width:100%;
					padding: 30px 30px 85px;
					height:auto;
					float: none!important;
				}
				.expertise {
					font-size: 9.5px;
					color:#a1a1a1;
					letter-spacing: 2px;
					font-weight: $semi-bold;
					text-transform: uppercase;
					margin-bottom: 14px;
					display: none;
				}
				.titre-post {
					font-size: 18.5px;
					line-height: 1.45;
					font-weight: $semi-bold;
					color:$touche_de_couleur;
					@include brk($m) {
						font-size: 16.5px;
					}
				}
				.lire-la-suite {
					color:$touche_de_couleur2;
					font-size:13px;
					font-weight: $bold;
					position: absolute;
					bottom:20px;
					left:30px;
					opacity: 0;
					transition: opacity 0.3s;
					@include brk($t) {
						opacity: 1
					}
				}
			}
			&:hover {
				box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.10);
				.lire-la-suite {
					opacity: 1;
				}
			}
		}
	}
}