/* BANNER NAVIGATION
======================================*/
#header_behind {
    height:$header-height;
    background: #fff;
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {padding-left:14px;height:$header-height-mobile;}
}

#header {
    height:$header-height;
    position:fixed;
    top:0px;
    left:0px; 
    z-index: 111;
    border-bottom: 1px solid transparentize(#000, 0.95);
    @include brk($t) {
        border-bottom: 1px solid transparentize(#000, 1) !important;
    }
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    .background {
        opacity: 0;
    }
    .banner-content {
        height: 100%;
        margin: 0 auto;
        @include brk($header-getLarge) {
            margin:0; 
            padding:0 20px 0 6%;
            transition: 0.5s;
        }
        @include brk($t) {height:$header-height-tablet;}
        @include brk($m) {padding-left:14px;height:$header-height-mobile;}
    }
}

#header_top {
    height:72px;
    position: relative;
    z-index: 100;
    .banner-content {
        @include brk($t) {
            padding-right:0;
        }
    }
    .logo-container {
        position: relative;
        float: left;
    }
    .logo-image {
        transition: transform 0.6s;
        position: absolute;
        left:0;
        background-size:contain;
        width:100px;
        height:100px;
        display: inline-block;
        margin-left: 0px;
        float:left;
        height: 118px;
        width: 118px;
        top: 13px;
        margin-left: 10px;
        
        @include brk($t) {
            top: 13px;
            margin-top:2px;
            height:100px;
            width: 100px;
            .texte {display:none;}
        }
        @include brk($td) {
            margin-left: -5px;
        }
        @include brk($m) {
            margin-top:0px;
            height: 90px;
            width: 90px;
            top: 8px;
            margin-left: 10px;
        }
    }
}


#header .banner-content.hidden {
    position: absolute;
    left:0;
    right:0;
    @include brk($t) {
        position: fixed;
        padding:0;
        top:0;
    }
    .hidden-btn-container {
        position: absolute;
        bottom:-100px;
        width:390px;
        right:-30px;
        height:100px;
        overflow: hidden;
        @include brk($t) {
            right: 39px;
            width:390px;
            bottom:-33px;
            height:130px;
        }
        .diagnostic {
            position: absolute;
            right:30px;
            bottom:110px;
            height:72px;
            border-bottom-left-radius:10px !important;
            border-bottom-right-radius:10px !important;
            transition: transform 0.8s;
            box-shadow: 0px 3px 28px 0px transparentize(#095c80, 0.8);
            transform: translate3d(0,-10px,0);
            @include brk($t) {
                transform: translate3d(0,100px,0);
                height: $header-height-tablet;
                right:30px;
                margin-right: 0!important;
                bottom:133px;
                border-bottom-right-radius:0px !important;
            }
        }
    }
}





#header .diagnostic, .diagnostic-tablet.tablet-on {
    height: 100%;
    float: right;
    display: inline-block;
    background:#fff;
    width:330px;
    padding-top:12px;
    position: relative;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    box-shadow: 0px 3px 28px 0px transparentize(#095c80, 0.8);
    @include brk($t) {
        display: none !important;
        position: fixed;
        right:0;
        top:0;
        z-index: 100;
        width:320px;
        padding-top:11px;
        height:$header-height-tablet;
        border-bottom-left-radius:10px !important;
        border-bottom-right-radius:0px;
        margin-right: $header-height-tablet;
    }
    @include brk($m) {
        display: none!important;
    }
    &:hover {
        // background:darken($touche_de_couleur2, 10%);
        .icon-arrow span {transform:translate3d(1px,0,0);}
        p {
            text-decoration: underline;
        }
    }
    p {
        padding-left:88px;
        user-select:none;
        color:#125c80;
        line-height: 1.45;
        letter-spacing:0.5px;
        font-size: 16px;
        font-weight: $bold;
        @include brk($t) {padding-left:83px;}
        &:nth-child(2) {
            text-transform: lowercase;
        }
    }
    .icon {
        width:42px;
        height:42px;
        position: absolute;
        top:14px;
        left:22px;
        color:$touche3;
        // .st0{clip-path:url(#XMLID_14_);fill:$touche3;}
        // .st1{clip-path:url(#XMLID_14_);fill:#fff;}
        @include brk($t) {top:13px;}
    }
    .icon-arrow {
        font-size: 30px;
        color:$touche1;
        width:40px;
        text-align: center;
        font-weight: $normal;
        height:48px;
        padding-top:7px;
        position: absolute;
        top:12px;
        line-height: 1;
        right:5px;
        border-left:1px solid rgba(255,255,255,0.3);
        span {
            transition: transform 0.5s;
            display: inline-block;
            // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
    }
}






#header .appel-gratuit {
    width:325px;
    padding-top:12px;
    border: 1px solid rgba(255,255,255,0.5);
    border-top:none!important;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    height: 100%;
    float: right;
    display: inline-block;
    margin-right: 24px;
    position: relative;
    cursor: default;
    @include brk($t) {
        display: none;
    }
    &:hover {
        // background:darken(#1599d6, 25%);
        // border: 1px solid darken(#1599d6, 25%);
    }
    p {
        color: #fff;
        padding-left:88px;
        font-size: 15px;
        font-weight: $normal;
        &.bold {
            font-size: 16px;
            font-weight: $bold;
            line-height:1.5;
        }
        span {
            color:#104b99;
        }
    }
    .icon {
        width:42px;
        height:42px;
        position: absolute;
        top:14px;
        left:22px;
        .st0{fill:#FFFFFF;}
    }
}


#header_bottom {
    height:78px;
    .banner-content .content {
        border-bottom: 1px solid rgba(255,255,255,0.15);
    }
    @include brk($t) {display:none;}
}

#nav_desktop {
    display:inline-block;
    height:100%;
    float:right;
    padding-top:16px;
    margin-right: -12px;
    @include brk($menu-off) {display:none;}
    >ul {
        list-style-type:none;
        height:100%;
        user-select: none;
        >li {
            display:inline-block;
            height: 100%;
            margin-left:-5px;
            user-select: none;
            padding:0 19px;
            position: relative;
            float: left;
            // &.selected a, &.current-menu-item a, &.current_page_ancestor a {border-bottom:4px solid white;}
            // &:hover a {border-bottom:4px solid white;cursor:pointer!important}
            &:hover, &.current-menu-item, &.current-menu-ancestor, &.current_page_ancestor, &.selected {
                >a {color:transparentize(#0090c6,0);}
            }
            a {
                display: inline-block;
                padding: 18px 0 20px 0;
                font-size: 16px;
                font-weight: $bold;
                color: #125c80;
                // text-transform: uppercase;
                height:100%;
                user-select: none;
                line-height: 1.3;
            }

            // HOME
            &:first-child {
                position:relative; 
                a::after {
                    content:"";
                    width:26px;
                    height: 27px;
                    display: inline-block;
                    position:absolute;
                    top:14px;
                    left:18px;
                    cursor:pointer!important;
                    background:url('../assets/images/icon-home.svg');
                }
                a {color: rgba(0,0,0,0);width:23px;}
            }
        }
    }


    // SOULIGNÉ ----------------------- 

    >ul>li:not(.btn)>a:before {
        content:"";
        width:0px;
        opacity: 0;
        transition: width 0.2s, opacity 0.5s, background 0.3s;
        height:5px;
        position: absolute;
        display: inline-block;
        background:#125c80;
        left:20px;
        bottom:-1px;
    }
    >ul>li:not(.btn):hover >a:before,
    >ul>li:not(.btn).current-menu-ancestor >a:before,
    >ul>li:not(.btn).current-menu-item >a:before {
        opacity: 1;
        width:calc(100% - 39px);
        background: #0090c6;
    }
    &.page>ul>li:not(.btn)>a:before {
        background:#0090c6;
    }



    // SEPARATION VERTICALE -----------------

    >ul>li:after {
        content:"";
        width:1px;
        height:15px;
        position: absolute;
        display: inline-block;
        background:#125c80;
        opacity: 0.2;
        top:22px;
        right:0px;
    }
    >ul>li:first-child:after {display:none;}
    >ul>li:last-child:after {display:none;}
    // >ul>li:nth-child(2):after {display:none;}

    &.page>ul>li:after {
        background:#1599d6;
        opacity: 0.5;
    }

    >ul>li>ul {
        display: none;
    }




    // FLECHE PARENT -----------------

    >ul>li.menu-item-has-children {
        position: relative;
        >a {padding-right:20px;}
        >a::after {
            content:"";
            width:7px;
            height:8px;
            display: inline-block;
            position:absolute;
            top:26px;
            right:21px;
            cursor:pointer!important;
            background:url('../assets/images/parent-arrow.svg');
        }
    }


}









//  LEVEL 2   ------------------------------------


#nav_desktop >ul>li {
    &:hover ul {
        display:block;
    }
    >ul {
        position:absolute;
        left:0px;
        top:63px;
        margin-left: -22px;
        margin-top: 0px;
        display: none;
        z-index: 10;
        padding: 0 60px 60px 40px;
    }
    >ul>li {
        background:#fff;
        display:inline-block;
        float:left;
        line-height: 1;
        border-top: 1px solid transparentize(#000, 0.9);
        transition: border 1s;
        box-shadow: 2px 4px 14px 0px transparentize($touche1, 0.91);
        &:first-child {
            border:none!important;
            a {
                // border-top: 3px solid transparentize($touche1, 0)!important;
            }
        }
        &.current-menu-item, &:hover {
            background:lighten(#000, 95%);
            &, & + li {
                transition: border 0.5s;
                border-top: 1px solid transparentize(#000, 1);
            }
        }
        &.current-menu-item>a {
            color:$touche2;
            font-weight: $bold;
            &::after {display:none}
        }
        >a {
            line-height: 1;
            font-size:15px;
            font-weight: $bold;
            color: $touche1;
            width:290px;
            padding: 26px 30px;
            // text-transform: uppercase;
            letter-spacing: 0.2px;
            position: relative;
            &::after {
                content:"›";                
                font-weight: $normal;
                font-size: 23px;
                position: absolute;
                display: inline-block;
                transition: transform 0.15s;
                top:20px;
                right:25px;
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                    transform: translate3d(1px,0,0);
                }
            }
        }
    }
    // &.current_page_ancestor>ul {display:inline-block;}
    >ul>li.menu-item-has-children.current-menu-item ul{display:block}
    >ul>li>ul {
        display: block;
        position:absolute;
        top:100px;
        left:0px;
        height:100px;
        display:none!important;
        background:$level3; width:40000px;
        >li {
            display:inline-block;
            float:left;
            height:100%;
            &:first-child a {padding-left:0}
            a {
                padding-top:42px;
                display:inline-block;
                font-size:13px;
                font-weight: 700;
                color:#333333;
                &:hover {color:$dark-grey;}
            }
        }
    }
}














