// DJO RESET


html,body {height:100%;}

html {
  font-family: sans-serif;
  box-sizing: border-box;

  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */

  //Prevent iOS text size adjust after orientation change, without disabling user zoom.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  // Anti-aliasing for safari
  -webkit-text-stroke: rgba(255,255,255,0.01) 0.1px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(255,255,255,0.01) 0 0 1px; // http://blakepetersen.io/how-to-clean-up-chrome-and-safaris-webfont-rendering/

  // Firefox/Mac : Fix for weight difference
  -moz-osx-font-smoothing: grayscale;
}

div {line-height: initial;}

* {
  box-sizing: inherit;
  margin:0;
  padding:0;
  max-height: 100000px;
  border:0;
  background:none;
  background-repeat:no-repeat!important;
  // Disable mobile browser click/touch/tap feedback box
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // -webkit-user-modify: read-write-plaintext-only; 
  // -webkit-tap-highlight-color: transparent;  // i.e. Nexus5/Chrome and Kindle Fire HD 7''
  // :focus {outline: 0;border:none;color: rgba(0, 0, 0, 0);}
}

*::before, *::after {
  margin:0;
  padding:0;
  background-repeat:no-repeat!important;
  box-sizing: inherit;
}


/* HTML5 display definitions
   ========================================================================== */


// Correct `block` display not defined for any HTML5 element in IE 8/9.
// Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
// Correct `block` display not defined for `main` in IE 11.

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,
nav,section,summary {display: block;}

// 1. Correct `inline-block` display not defined in IE 8/9.
// 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.

audio,canvas,progress,video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

// Prevent modern browsers from displaying `audio` without controls.
// Remove excess height in iOS 5 devices.

audio:not([controls]) {display: none;height: 0;}

// Address `[hidden]` styling not present in IE 8/9/10.
// Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.

[hidden],template {display: none;}



/* Links
   ========================================================================== */

// Remove the gray background color from active links in IE 10.
a {background-color: transparent;}

// Improve readability when focused and also mouse hovered in all browsers.
a:active,a:hover {outline: 0;}



/* Text-level semantics
   ========================================================================== */


// Address styling not present in IE 8/9/10/11, Safari, and Chrome.
abbr[title] {border-bottom: 1px dotted;}

// Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
b, strong { font-weight: bold; }

// Address styling not present in Safari and Chrome.
dfn {font-style: italic;}

// Address styling not present in IE 8/9.
mark {background: #ff0;color: #000;}

// Address inconsistent and variable font size in all browsers.
small {font-size: 80%;}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {top: -0.5em;}
sub {bottom: -0.25em;}



/* Embedded content
   ========================================================================== */

// Correct overflow not hidden in IE 9/10/11.
svg:not(:root) {overflow: hidden;}



/* Grouping content
   ========================================================================== */


// Address margin not present in IE 8/9 and Safari.
figure {margin: 1em 40px;}


// Address differences between Firefox and other browsers.
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

// Contain overflow in all browsers.
pre {overflow: auto;}


// Address odd `em`-unit font size rendering in all browsers.
code,kbd,pre,samp {
  font-family: monospace, monospace;
  font-size: 1em;
}


/* Forms
   ========================================================================== */

// Known limitation: by default, Chrome and Safari on OS X allow very limited
// styling of `select`, unless a `border` property is set.

// 1. Correct color not being inherited.
//    Known issue: affects color of disabled elements.
// 2. Correct font properties not being inherited.

button,input,optgroup,select,textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
}

// Address `overflow` set to `hidden` in IE 8/9/10/11.
button {overflow: visible;}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
// Correct `select` style inheritance in Firefox.

button,select {text-transform: none;}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

// Re-set default cursor for disabled elements.
button[disabled], html input[disabled] { cursor: default;}

// Remove inner padding and border in Firefox 4+.
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}



// INPUT

input {
    outline:0;
    text-indent: 2px;
    // Address Firefox 4+ setting `line-height` on `input` using `!important` in the UA stylesheet.
    line-height: normal;
    // Turn off iPhone/Safari input element rounding
    -webkit-appearance: none;
    -webkit-border-radius:0px;
    border-radius: 0; // for all platforms
}

// Remove inner padding and search cancel button in Safari and Chrome on OS X.
// Safari (but not Chrome) clips the cancel button when the search input has
// padding (and `textfield` appearance).

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}


// 1. Address `appearance` set to `searchfield` in Safari and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari and Chrome
//    (include `-moz` to future-proof).

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

// Fix the cursor style for Chrome's increment/decrement buttons. For certain
// `font-size` values of the `input`, it causes the cursor style of the
// decrement button to change from `default` to `text`.

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {height: auto;}


// It's recommended that you don't attempt to style these elements.
// Firefox's implementation doesn't respect box-sizing, padding, or width.
// 1. Address box sizing set to `content-box` in IE 8/9/10.

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; /* 1 */
}


//  Removing 'blue border' from input text field
input:focus, select:focus, textarea, div {
    outline: none !important;
}


// removing red box on firefox - required fields
input:required {
    box-shadow:none;
}


// Remove IE10's “clear field” X button

input::-ms-clear, textarea::-ms-clear {
    display: none;
}


// Fix for Firefox Placeholder

input::placeholder, textarea::placeholder {
    opacity: 1;
}


// Define consistent border, margin, and padding.
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

// Remove default vertical scrollbar in IE 8/9/10/11.
textarea {overflow: auto;}


// Don't inherit the `font-weight` (applied by a rule above).
// NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
optgroup {font-weight: bold;}


/* Tables
   ========================================================================== */

// Remove most spacing between table cells.

table {
  border-collapse: collapse;
  border-spacing: 0;
}



/* BODY HEIGHT = min VIEW PORT HEIGHT 
   http://community.sitepoint.com/t/100-min-height-viewport/6449/2 
   ========================================================================== */


// Opera min-height 100% bug
body:before {
    content:"";
    height:100%;
    float:left;
    width:0;
    margin-top:-32767px;
}

// After for IE8 min-height 100% bug
#body_wrapper::after {
    clear:both;
    content:"";
    display:block;
    height:1%;/*fix IE8*/
    font-size:0;
}

// IE6 FIX
* html #body_wrapper {height:100%;}




/* A RANGER 
   ========================================================================== */


p, li, h1, h2, h3, h4, h5, h6 {cursor:default;}

a {
    text-decoration:none;
    display: inline-block;
    p, h1, h2, h3, h4, h5, h6 {cursor:inherit;}
}

button {
    p, h1, h2, h3, h4, h5, h6 {cursor:inherit;}
}

a, button {outline:0}

ul {list-style-type:none;}







/* ANIMATION DEFAULT 
   ========================================================================== */


* {
  // animation-fill-mode: both !important;
}



// NEW STUFF -------------------------

img {
  user-select:none;
}

a {
  -webkit-text-stroke: blue;
}

nav {
  user-select:none;
}

div {
  line-height: 1;
}

ul {
  display: inline-block;
}

a.no-href {
  cursor: default;
}