

// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 550px;
$page_wrapper-bannerTop-laptop: 500px;
$page_wrapper-bannerTop-tablet: 393px;
$page_wrapper-bannerTop-mobile: 393px;

.page-a .banner.top {
    // margin-top: $header-height;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { 
        height:$page_wrapper-bannerTop-tablet; 
        margin-top: $header-height-tablet;
    }
    @include brk($m) { 
        height:$page_wrapper-bannerTop-mobile; 
        margin-top: $header-height-mobile;
    }
}
.page-a .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.page.page-a .banner.top {
	background:#fff;
	.image {
		background-size:cover;
		background-position: 50% 50%;
		@include brk($t + 65px) {
			background-size:cover;
			background-position: 90% 50%;
		}
		@include brk($m) {
			background-position: 70% 50%;
		}
	}
	.image-container {
		@include brk($m) {
			opacity:0.15;
		}
	}
	.bloc {
		padding-top: 40px;
		.sur-titre {
			color:$touche1;
		}
	    .titre {
	        color:$touche2;
	        font-weight: $light;
	    }
	}
}



.flex-focus_fond_bleu {
	margin-bottom:10px;
	@include brk($t) {
		margin-bottom:10px;
	}
	@include brk($m) {
		margin-bottom:0px;
	}
}





.page-a, 
.page.parent.single {
	.banner.top .icon-page {
		position: absolute;
		top:37px;
		right:0px;
		height:115px;
		width:115px;
		.icon {
			animation: fade 1s;
		}
		@include brk($t) {
			height:105px;
			width:105px;
		}
		@include brk($td) {
			height:90px;
			width:90px;
		}
		@include brk($m) {
			top:32px;
			height:70px;
			width:70px;
			right:0px;
		}
	}
}


.page.single.solution.mere {

	.banner.ariane  {
		height:80px!important;
		* {
			opacity:0!important;
		}
	}

	.banner.solutions.second {
		padding-bottom:100px;
	}
}