

// ****************************************NOTE****************************************

// https://css-tricks.com/snippets/css/using-font-face/

// EOT :        Embedded OpenType for IE 9
// EOT?#iefix : Embedded OpenType for older versions of IE
// WOFF :       Web Open Font Format (newer format by Mozilla, for all modern browsers)
// WOFF2 :      It loads super fast, data in the file is compressed
// TTF :        TrueType Format (Safari, Android, iOS)
// SVG :        Legacy iOS

// *************************************************************************************




@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/montserrat-regular-webfont.eot');
    src: url('../assets/fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/montserrat-regular-webfont.woff') format('woff'),
         url('../assets/fonts/montserrat-regular-webfont.ttf') format('truetype'),
         url('../assets/fonts/montserrat-regular-webfont.svg#svgMontserrat') format('svg');
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/montserrat-bold-webfont.eot');
    src: url('../assets/fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../assets/fonts/montserrat-bold-webfont.woff') format('woff'),
         url('../assets/fonts/montserrat-bold-webfont.ttf') format('truetype'),
         url('../assets/fonts/montserrat-bold-webfont.svg#svgMontserrat') format('svg');
    font-weight: 700;
    font-style: normal;
}



@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/montserrat-light-webfont.eot');
    src: url('../assets/fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../assets/fonts/montserrat-light-webfont.woff') format('woff'),
         url('../assets/fonts/montserrat-light-webfont.ttf') format('truetype'),
         url('../assets/fonts/montserrat-light-webfont.svg#svgMontserrat') format('svg');
    font-weight: 300;
    font-style: normal;
}



@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/montserrat-hairline-webfont.eot');
    src: url('../assets/fonts/montserrat-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/montserrat-hairline-webfont.woff2') format('woff2'),
         url('../assets/fonts/montserrat-hairline-webfont.woff') format('woff'),
         url('../assets/fonts/montserrat-hairline-webfont.ttf') format('truetype'),
         url('../assets/fonts/montserrat-hairline-webfont.svg#svgMontserrat') format('svg');
    font-weight: 100;
    // font-style: normal;
}

