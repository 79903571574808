
$headroom_background: #fff;
$headroom_background_fade_out: 1s;
$headroom_background_fade_in: 0.6s;
$headroom_background_opacity: 0.98;

$headroom_speed_in: 0.6s;
$headroom_speed_out: 0.8s;


@keyframes slideUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(-87px); }
}

@keyframes slideDown {
    0% { transform: translateY(-87px); }
    100% { transform: translateY(0); }
}


header#header.headroom {
    will-change: transform;
    animation-duration: $headroom_speed_in;
    animation-fill-mode: both;
    // animation-timing-function: ease-in-out;
    .background {
        background: $headroom_background;
        transition: opacity $headroom_background_fade_in;
        opacity: $headroom_background_opacity;
    }
    &.headroom--top {
        .background {
            opacity: 0!important;
            transition: opacity $headroom_background_fade_in;
        }
    }
    &.headroom--pinned {
        animation-name: slideDown;
        .background {
            transition: opacity $headroom_background_fade_in;
            opacity: $headroom_background_opacity;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp;

        .logo-image {
            transform: scale(0.5) translate(-58px,85px);
            @include brk($t) {
                transform: scale(0.9) translate(-65px,100px);
            }
            @include brk($td) {
                transform: scale(0.9) translate(0px,-30px);
            }
            @include brk($tp) {
                transform: scale(0.9) translate(0px,-30px);
            }
        }
        
        .background {
            transition: opacity $headroom_background_fade_out;
            opacity: $headroom_background_opacity;
        }
        .hidden-btn-container .diagnostic {
            transition: transform 0.8s;
            transform: translate3d(0,81px,0);
            @include brk($t) {
                transform: translate3d(0,100px,0);
            }
        }
    }
}



#hamburger_container.headroom, .calling-icon.headroom{
    will-change: transform;
    animation-duration: $headroom_speed_in;
    animation-fill-mode: both;
    // animation-timing-function: ease-in-out;
    &.headroom--top {
    }
    &.headroom--pinned {
        animation-name: slideDown;
        @include brk($t) {
            animation-name: none;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp;
        @include brk($t) {
            animation-name: none;
        }
    }
}

