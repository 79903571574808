// Hamburger
// ==================================================
.hamburger {
  z-index: 100;
  width: 100%;
  height:100%;
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: $hamburger-animation-transition-duration !important;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}





// .hamburger.is-active.back-arrow {
//   .hamburger-inner {
//     background:rgba(0,0,0,0)!important;
//     transform: rotate(0deg);
//     transition-duration: 0.1s;
//     transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     &,
//     &::before,
//     &::after {
//       width: $hamburger-layer-width;
//       height: $hamburger-layer-height;
//       background-color: $hamburger-layer-color;
//       border-radius: $hamburger-layer-border-radius;
//       position: absolute;
//       transition-property: transform;
//       transition-duration: $hamburger-animation-transition-duration !important;
//       transition-timing-function: ease;
//     }

//     &::before {
//       transition: top 0.1s 0.14s ease, 
//             transform 0.1s 0.14s ease,
//               opacity 0s ease;
//       opacity: 1;
//       top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1 + 4px;
//       transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
//     }

//     &::after {
//       transition: bottom 0.1s 0.14s ease, 
//             transform 0.1s 0.14s ease,
//                     opacity 0.1s ease;
//       bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1 + 3px;
//       transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
//     }
//   }
// }