



// DESKTOP -------------------------------------------


#header {
    &.page {
        .wpml-ls-legacy-dropdown, #lang_sel {
            >ul>li>a {
                color:#fff !important;
                span::after {
                    background: url('../assets/images/icon-wpml-down1.svg')
                }
            }
        }
    }
    .wpml-container {
        &.home {
            .wpml-ls-legacy-dropdown {
                >ul>li>a {
                    // color: transparentize(#fff, 0.1);
                }
            }
        }
    }
}



#header .wpml-container {
    transition: transform 0.6s;
    height: 100%;
    display: inline-block;
    float: right;
    margin-left: 20px;
    position: relative;
    &:before {
        content:"";
        width:1px;
        height:15px;
        position: absolute;
        display: inline-block;
        background:#125c80;
        opacity: 0.2;
        top:38px;
        left:-7px;
    };
    .wpml-ls-legacy-dropdown, #lang_sel {
        height: 100%;
        position: relative;         
        @include brk($menu-off) {
            display: none;
        }
        >ul {
            height: 100%;
            >li { 
                height: 100%;
                outline: 0 none !important; 
                a {
                    font-size: 14px;
                    font-weight: $bold;
                    color: transparentize(#125c80, 0.1);
                    font-family: $family1;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    user-select: none;
                }
                >a {
                    height: 100%;
                    padding:  39px 16px 10px 12px;
                    span {
                        position: relative;
                        &::after {
                            content:"";
                            width:8px;
                            height:8px;
                            position:absolute;
                            top:6px;
                            right:-14px;
                            background-size:contain;
                            background-repeat: no-repeat;
                            background-image:url('../assets/images/parent-arrow.svg');
                        }
                    }
                }
                >ul {
                    visibility:hidden;
                    position:absolute;
                    top:calc(100% - 5px);
                    left:-11px;
                    z-index: 100;
                    padding: 0 20px 20px;
                    >li {
                        height: 40px; 
                        width: 40px;
                        background:#eeeeee;
                        border-radius:50%;
                        margin-bottom: 9px;
                        &:hover {background:$white;}
                        >a {
                            box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
                            width: 100%;
                            height:100%;
                            text-align: center;
                            padding-top:14px;
                            border-radius:50%;
                        }
                    }
                }
            }
        }
        ul:hover, ul:hover ul {
            visibility:visible !important;
        }
        >ul:hover >ul>li>a {
            color: transparentize($touche2, 0);
        }
    }
}















// MOBILE --------------------------






// WPML FLAT FOOTER -----------------------------------------------------


#nav_mobile .wpml-ls-statics-footer.wpml-ls-legacy-list-horizontal {
    position: absolute;
    // height:100%;
    padding-left:12px;
    overflow: hidden;
    z-index: 1012;
    animation: fade 2s;
    padding-left: 101px;
    @extend .bar-top;
    // padding-right:5%;
    >ul {
        height: 100%;
    }
    >ul>li>a {
        font-size: 11.5px;
        font-weight: $bold;
        font-family: $f1;
        color:transparentize(#fff, 0);
        letter-spacing:2px;
        padding: 0px 13px;
        display: inline-block;
        height:100%;
        text-transform: uppercase;
    }
    >ul>li {
        height: 100%;
        display: inline-block;
        position: relative;
        &.wpml-ls-current-language {
            a {
                text-decoration: underline;
            }
        }
        &:before {
            content: "";
            background:transparentize(#2e2b26, 0.5);
            height:15px;
            width:1px;
            display: inline-block;
            position: absolute;
            left:0;
            top:28px;
        }
        &:first-child:before {
            display: none;
        }
    }
}







// WPML DROPDOWN -----------------------------------------------------


#nav_mobile .wpml-ls-legacy-dropdown,
#nav_mobile #lang_sel {
    position: absolute;
    top:0;
    right: $header-height-tablet;
    height: $header-height-tablet;
    display: inline-block;
    transition: transform 0.6s;
    z-index: 1012;
    @include brk($m) {
        height: $header-height-mobile;
        right: $header-height-mobile;
    }
    >ul {
        height:100%;
        >li { 
            height: 100%;
            outline: 0 none !important; 
        }
    }
    a {
        display: flex;
        align-items:center;
        font-size: 14px;
        font-weight: $normal;
        color: transparentize(#fff, 0.1);
        font-family: $f1;
        text-transform: uppercase;
        width: $header-height-tablet;
        animation: fade 2s ease 0.1s both;
        @include brk($m) {
            width: $header-height-mobile;
        }
    }
    >ul:hover >ul>li>a {
        color: transparentize($touche2, 0);
    }
    >ul>li>a {
        height:100%;
        padding: 0px 16px 0px 12px;
        span {
            position: relative;
            &::after {
                content:"";
                width:8px;
                height:8px;
                position:absolute;
                top:6px;
                right:-14px;
                background-size:contain;
                background-repeat: no-repeat;
                background-image: url('../assets/images/icon-wpml-down2.svg')
            }
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        position:absolute;
        // height:100%;
        top:$header-height-tablet - 8px;
        left:-12px;
        z-index: 100;
        padding: 0 20px 20px;
        @include brk($m) {
            top:$header-height-mobile - 8px;
        }
    }
    >ul>li>ul>li {
        height: 40px; 
        width: 40px;
        background:#fff;
        border-radius:50%;
        margin-bottom: 9px;
        &:hover {background:$white;}
    }
    >ul>li>ul>li>a {
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height:100%;
        text-align: center;
        padding-top:8px;
        border-radius:50%;
        color: #000;
    }
}
