
// Order is respected
// Folders can be imported, using gulp-sass-bulk


@import "base/0__reset";
@import "base/1__breakpoint";
@import "base/3__variables";
@import "base/5__classes";
@import "base/6__big-divs";
@import "base/7__animations";
@import "base/8__when-visible.scss";
@import "base/9__loaders";




@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_commun.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_fontface.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_footer.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_header.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_headroom.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_mobile.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_nav-horizontal.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/_wpml.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/hamburger/00_hamburger.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/hamburger/01_base.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/elements/hamburger/02_squeeze.scss";

@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_404.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_archive-articles.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_front-page.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_page-A.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_page-B.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_page-contact-form.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_page-contact.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_page-gdpr.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_page-produit.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_page-produits.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_pages.scss";
@import "C:/xampp/htdocs/AIRLABEL/_PUBLIC/wp-content/themes/AIRLABEL/__src/scss/pages/_single.scss";



