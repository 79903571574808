.page.single.404 {
    background: #fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 393px;
$page_wrapper-bannerTop-laptop: 340px;
$page_wrapper-bannerTop-tablet: 280px;
$page_wrapper-bannerTop-mobile: 250px;

#page_404.page.single .banner.top {
    margin-top: $header-height;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    .image {
        @include brk($t) { 
            background-position: 60% 50%;
        }
        @include brk($t) { 
            background-position: 66% 50%;
        }
    }
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { 
        height:$page_wrapper-bannerTop-tablet; 
        margin-top: $header-height-tablet;
    }
    @include brk($m) { 
        height:$page_wrapper-bannerTop-mobile; 
        margin-top: $header-height-mobile;
    }
}
#page_404.page.single .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}



#page_404 .banner.medium .banner.solutions {
    height:auto;
    background: #fff!important;
    padding-bottom: 135px;
    @include brk($td) { padding-bottom: 120px; }
    @include brk($m) { padding-bottom: 90px; }
}