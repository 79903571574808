
@keyframes arrow_move_down {
    0% {bottom:1px;}
    100% {bottom:-9px;}
}

.page .banner.nav {
	// margin-top:-5px;
	display:block;
	position: relative;
	min-height:84px;
	margin-top: -3px;
	transition: background 1s;
	background:#0185b6;
	z-index:10;
	@include brk($m) { margin-top:0px; }
	.couleur-overlay {
		@extend .abs;
	}

	nav#nav_child {
		position: relative;
		height: 100%;


		>ul>li>a {
			display: none;
		}

		&:not(.parent) {
			>ul>li>a, 
			>ul>li:not(.current-menu-parent)>ul>li, 
			// >ul>li:not(.current-menu-parent)>ul,
			>ul>li>ul>li>a, 
			// >ul>li>ul>li:not(.current-menu-parent)>ul>li, 
			// >ul>li>ul>li:not(.current-menu-parent)>ul,
			{
		        // display: none;
		    }
		}
		&.parent {
			>ul>li>a, 
			>ul>li>ul>li>a 
			// >ul>li:not(.current-menu-parent)>ul>li, 
			// >ul>li:not(.current-menu-parent)>ul 
			{
		        // display: none;
		    }
		}

		ul {
			width: 100%;
			height: 100%;
		}


		// LEVEL 2  -----------------
		>ul>li {
			transition: background 2s;
			display:none;@extend .cache-left, .cache-right}
		>ul>li.current-menu-item,
		>ul>li.current-menu-parent {
			display:block!important;
			animation: fade 1s;
			// >a {display:none;}
			>ul>li {
				display: inline-block;
				position: relative;
				float: left;
				@include brk($m) { 
					&, &>a {width:100%; }
					&:first-child a {padding-top:2px;}
					&:last-child a {padding-bottom:2px;}
				}
				// a {background:rgba(0,0,0,0.4)}
				.bar-underline {
					display: inline-block;
					position: absolute;
					bottom:0px;
					left:0;
					height:0px;
					width:100%;
					text-align: center;
					// background: darken($touche_de_couleur, 10%);
					@include brk($m) { display:none }
					.arrow-down {
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						position: absolute;
						left:calc(50% - 10px);
						opacity: 0;
						top:0px;
						@include brk($t) { display:none!important }
						// display: none;
					}
				}
				
				&.active, &.current_page_item, &:hover {
					background:$touche1 !important;
					a {background:transparent}
					a .bar-underline {
						transition: bottom 0.3s, height 0.3s;
						height: 9px;
						bottom:0px;
					}
					&::after {
						content:"";
						display: inline-block;
						position: absolute;
						bottom:0px;
						left:0;
						height:9px;
						width:100%;
						opacity: 1;
						background: darken(#117aab, 0%);
						@include brk($m) { display:none }
					}
				}
				&.active .arrow-down {
					display: inline-block;
					transition: top 0.3s;
					// animation: arrow_move_down 1s;
					opacity:1;
					top:9px;
				}
				a {
					height:84px;
					padding:35px 23px 0px;
					vertical-align: middle;
					// @extend .bloc;
					color:#fff;
					letter-spacing: 0;
					text-align:center;
					// animation: fade 2s;
					font-size: 14px;
					font-weight: $normal;
					@include brk($m)  { 
						height: auto;
						border-top: 1px solid transparentize(#fff, 0.9);
						padding:18px $padding-mobile; 
					}
				}
				&:nth-child(1) a, &:nth-child(7) a {
					// padding:34px 23px 0px;
				}
			}
		}
	}
}
