
.page-produits .banner.top {
    background: #fff !important;
}


.page-produits .banner.medium {
    padding-top: 90px;
    margin-bottom: 50px;
	ul {
        // background: green;
        // height:684px;
        // display: inline-block;
        // width: calc(100%);
        position: relative;
        width: 100%;
        li a {
            background: #fff;
        }
        li {
            height:100%;
            height:342px;
            width:20%;
            float: left;
            position: relative;
            z-index: 100;
            border: 1px solid transparentize(#eee, 0);
            margin-left:-1px;
            @for $i from 1 through 10 {
                &:nth-child(#{$i})  { 
                    transition-delay: ((0.05s * $i) - 0.05s);
                }
            }
            &:first-child {margin-left:0px;}
            // margin-right: 15px;
            @include brk($t) {
                width:33.33%;
            }
            @include brk($m) {
                width:100%;
                margin-bottom:10px;
                &.mobile-on {
                    height:63px;
                    margin-top: 25px;
                    a {
                        z-index: 100;
                        animation: fade 1s;
                        border-radius:8px;
                        position: relative;
                        height:63px;
                        &:hover {
                            background:darken(#14c2ab, 5%);
                        }
                        .icon {
                            background: #fff;
                            position: absolute;
                            top:12px;
                            left:10px;
                            border-radius:50%;
                            height:38px;
                            width:38px;
                        }
                        .texte {
                            padding-left: 67px;
                            padding-top:13px;
                            p {
                                font-weight: $bold;
                                font-size: 15px;
                                padding-top:8px;
                                padding-bottom:5px;
                                text-align: left;
                                padding-left:20px;
                                border-left:1px solid transparentize(#fff, 0.7);
                            }
                        }
                    }
                }
            }
            &:hover {
                div.hover {display:inline-block;}
                // border: 1px solid transparentize($touche1, 0);
            }

        }
        .image {
            height:80%;
            width: 70%;
            left:15%;
            background-size:contain;
        }
        .label {
            width: 79px;
            height: 50px;
            position: absolute;
            top: 3px;
            right: 0;
        }
        .titre-container {
            height: 52px;
            line-height: 52px;
            position: absolute;
            left:0;
            bottom:15px;
            width:100%;
            @include brk($tp) {
                bottom:14px;
            }
            @include brk($m) {
                bottom:10px;
            }
            .titre {
                @extend .bloc;
                font-size: 17.5px;
                font-weight: $bold;
                color: #104b99;
                text-align: center;
                padding: 0 20px; 
                line-height: 1.5;
                br {
                    // display: none;
                    @include brk($m) {
                        // display: inline;
                    }
                }
            }
        }
        div.hover {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background: $touche_de_couleur;
            padding:50px 10% 30px;
            display: none;
            animation: fade 0.1s;
            border: 1px solid transparentize($touche_de_couleur, 0);
            @include brk($t) {padding:50px 11% 30px;}
            @include brk($td) {display:none!important}
        }
        @keyframes up_fade {
            0% {
                opacity: 0;
                transform: translate3d(0px, 5px, 0);
            }
            100% {
                opacity: 1;
                transform: translate3d(0px, 0px, 0);
            }
        }
        .titre-survol {
            font-size: 20px;
            font-weight: $bold;
            color: #fff;
            text-align: center;
            line-height: 1.25;
            margin-bottom: 15px;
            animation: up_fade 0.4s;
        }
        .texte-survol p, p {
            font-size: 15px;
            font-weight: $light;
            color: #fff;
            text-align: center;
            animation: up_fade 0.5s;
        }
        .en-savoir-plus-container {
            position: absolute;
            bottom:48px;
            left:0;
            width:100%;
            text-align: center;
            p {
                display: inline-block;
                padding-bottom:0px;
                line-height: 1;
                animation: fade 0.8s;
                border-bottom:1px solid #fff;
            }
        }
    }
}