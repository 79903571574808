



.page-produit .banner.top {
    .btn-retour {
        height:60px;
        background: $touche_de_couleur;
        display: inline-block;
        float: left;
        position: relative;
        // animation: fade 1.2s;
        position: absolute;
        bottom: 0;
        left: 0;
        .icon {
            position: absolute;
            height:14px;
            width:14px;
            left:32px;
            top:24px;
            transform: rotate(180deg);
                transition: transform 0.3s;
            .st0 {
                fill:#fff;
            }
            @include brk($m) {
                top: 24px;
            }
        }
        p {
            color:#fff;
            text-transform: uppercase;
            padding-left: 81px;
            padding-top: 23px;
            padding-right: 40px;
            font-size: 10px;
            font-weight: $bold;
            letter-spacing: 1.5px;
        }
        @include brk($m) {
            width: 79px;
            p {display:none;}
        }
        &:hover {
            background: darken(#0d1221, 5%);
            .icon {
                transform: rotate(180deg) translate3d(3px,0,0);
            }
        }
    }
}