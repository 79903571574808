



.front-page .banner.solutions {
    margin-top: -300px;
    // display: none;
    @include brk($t) {
        margin-top: 0;
    }
}


.banner.solutions {
    .banner-content {
        background: #fff;
        padding-top: 60px;
        @include brk($td) {
            padding-top: 30px;
        }
        @include brk($tp) {
            padding-top: 0;
        }
    }
    .banner-content .content .titres-container {
        >.sur-titre {
            font-size: 11px;
            color:#1599d6;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin-bottom: 15px;
        }
        >.titre {
            font-size: 35px;
            color:#104b99;
            font-weight: $bold;
            position: relative;
            line-height: 1.2;
            margin-bottom: 30px;
            &:before {
                content:"";
                width:57px;
                height:1px;
                display: inline-block;
                position: absolute;
                left:-90px;
                top:28px;
                background:#1599d6;
                display: none;
                @include brk($l) {display:none;}
            }
            @include brk($t) { 
                margin-bottom: 30px;
            }
            @include brk($m) { 
                font-size: 27.5px;
                &:before {display:none;}
            }
        }
        .texte {
            margin-bottom: 70px;
            width: 65%;
            @include brk($td) {
                width: 100%;
            }
            @include brk($tp) {
                width: 80%;
                margin-left: 10%;
            }
            @include brk($m) {
                width: 100%;
                margin-left: 0;
            }
            p {
                font-size: 17.5px;
                font-weight: $bold;
                color: #3baad8;
            }
        }
        @include brk($tp) { 
            text-align: center;
        }
    }
}


.page .banner.solutions {
    .background {display:none;}
    .titres-container {
        text-align:left!important;
    }
}

.banner.solutions {
    padding-top:85px;
    padding-bottom:40px;
    margin-top: -5px;
    min-height: 700px;
    // height:769px;
    z-index: 81;
    @include brk($t) { 
        height:auto; 
        padding-top:67px;
        padding-bottom:40px;
    }
    @include brk($m) { 
        .banner-content {
            padding-left: 12%;
            padding-right: 12%;
        }
    }

    .content {
        @include brk($tp) {
            text-align: center;
        }
    }
    
    .btn-01 {
        position: absolute;
        top:15px;
        right:0;
        display: inline-block;
        background:#1599d6;
        border-radius:6px;
        height:63px!important;
        @include brk($td) {
            // display: none;
            position: relative;
            top: auto;
            right: auto;
            margin-top: 60px;
        }
        &:hover {
            background:linear-gradient( 0deg, rgb(87,182,66) 0%, rgb(141,194,62) 100%)!important;
        }
        p {
            padding-top:19px;
            color:#fff;
            cursor: pointer;
        }
        .icon {
            top:14px;
        }
    }
    ul {
        // background: green;
        // height:684px;
        // display: inline-block;
        // width: calc(100%);
        position: relative;
        width: 100%;
        li a {
            background: #fff;
        }
        li {
            height:100%;
            height:342px;
            width:20%;
            float: left;
            position: relative;
            z-index: 100;
            border: 1px solid transparentize(#eee, 0);
            margin-left:-1px;
            @for $i from 1 through 10 {
                &:nth-child(#{$i})  { 
                    animation-delay: ((0.05s * $i) - 0.05s);
                }
            }
            &:first-child {margin-left:0px;}
            // margin-right: 15px;
            @include brk($t) {
                width:33.33%;
            }
            @include brk($m) {
                width:100%;
                margin-bottom:10px;
                &.mobile-on {
                    height:63px;
                    margin-top: 25px;
                    a {
                        z-index: 100;
                        animation: fade 1s;
                        border-radius:8px;
                        position: relative;
                        height:63px;
                        &:hover {
                            background:darken(#14c2ab, 5%);
                        }
                        .icon {
                            background: #fff;
                            position: absolute;
                            top:12px;
                            left:10px;
                            border-radius:50%;
                            height:38px;
                            width:38px;
                        }
                        .texte {
                            padding-left: 67px;
                            padding-top:13px;
                            p {
                                font-weight: $bold;
                                font-size: 15px;
                                padding-top:8px;
                                padding-bottom:5px;
                                text-align: left;
                                padding-left:20px;
                                border-left:1px solid transparentize(#fff, 0.7);
                            }
                        }
                    }
                }
            }
            &:hover {
                div.hover {display:inline-block;}
                // border: 1px solid transparentize($touche1, 0);
            }

        }
        .image {
            height:80%;
            width: 70%;
            left:15%;
            background-size:contain;
        }
        // .label {
        //     width: 79px;
        //     height: 50px;
        //     position: absolute;
        //     top: 3px;
        //     right: 0;
        // }
        .titre-container {
            height: 52px;
            line-height: 52px;
            position: absolute;
            left:0;
            bottom:15px;
            width:100%;
            @include brk($tp) {
                bottom:14px;
            }
            @include brk($m) {
                bottom:10px;
            }
            .titre {
                @extend .bloc;
                font-size: 17.5px;
                font-weight: $bold;
                color: #104b99;
                text-align: center;
                padding: 0 20px; 
                line-height: 1.5;
                br {
                    // display: none;
                    @include brk($m) {
                        // display: inline;
                    }
                }
            }
        }
        div.hover {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background: $touche_de_couleur;
            padding:50px 10% 30px;
            display: none;
            animation: fade 0.1s;
            border: 1px solid transparentize($touche_de_couleur, 0);
            @include brk($t) {padding:50px 11% 30px;}
            @include brk($td) {display:none!important}
        }
        @keyframes up_fade {
            0% {
                opacity: 0;
                transform: translate3d(0px, 5px, 0);
            }
            100% {
                opacity: 1;
                transform: translate3d(0px, 0px, 0);
            }
        }
        .titre-survol {
            font-size: 20px;
            font-weight: $bold;
            color: #fff;
            text-align: center;
            line-height: 1.25;
            margin-bottom: 15px;
            animation: up_fade 0.4s;
        }
        .texte-survol p, p {
            font-size: 15px;
            font-weight: $light;
            color: #fff;
            text-align: center;
            animation: up_fade 0.5s;
        }
        .en-savoir-plus-container {
            position: absolute;
            bottom:48px;
            left:0;
            width:100%;
            text-align: center;
            p {
                display: inline-block;
                padding-bottom:0px;
                line-height: 1;
                animation: fade 0.8s;
                border-bottom:1px solid #fff;
            }
        }
    }
}



.banner.bottom .banner.solutions {
    background:#fff;
    height:auto;
    padding-bottom: 80px;
    .btn-01 {
        background:#1599d6;
        &:hover {
            background:linear-gradient( 0deg, rgb(87,182,66) 0%, rgb(141,194,62) 100%)!important;
        }
    }
}















.banner.articles {
    background:#fff;
    padding:60px 0 80px;
    z-index:80;
    @include brk($t) {
        padding:110px 0 80px;
    }
    @include brk($m) {
        padding:80px 0 50px;
    }
    .content {
        position: relative;
    }
    .titre-container {
        margin-bottom: 42px;
        .titre {
            color: #0e4285;
            font-size: 26px;
            font-weight: $bold;
        }
    }

    ul {
        width: 100%;
        li {
            height:250px;
            width:48.1%;
            float: left;
            background:#fff;
            margin-bottom: 20px;
            animation: fade 2s;
            transition: box-shadow 0.2s;
            box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.15);
            @include brk($t) {
                width:100%;
                float: none!important;
            }
            @include brk($m) {
                height:495px;
                height:auto;
            }
            &:nth-child(2n) {float:right;}

            .image-container {
                width:45%;
                position: relative;
                height:100%;
                float: left;
                @include brk($m) {
                    height:250px;
                    width:100%;
                    float: none;
                }
                .image {
                    animation: none;
                }
            }
            .textes-container {
                width:55%;
                height:100%;
                float: right;
                padding: 25px 30px;
                position: relative;
                @include brk($m) {
                    width:100%;
                    height:auto;
                    float: none!important;
                    padding-bottom: 75px;
                }
                .expertise {
                    font-size: 9.5px;
                    color:#a1a1a1;
                    letter-spacing: 2px;
                    font-weight: $semi-bold;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    display: none;
                }
                .titre-post {
                    font-size: 18.5px;
                    line-height: 1.45;
                    font-weight: $semi-bold;
                    color:$touche_de_couleur;
                    @include brk($m) {
                        font-size: 16.5px;
                    }
                }
                .lire-la-suite {
                    color:$touche_de_couleur2;
                    font-size:13px;
                    font-weight: $bold;
                    position: absolute;
                    bottom:20px;
                    left:30px;
                    opacity: 0;
                    transition: opacity 0.3s;
                    @include brk($t) {
                        opacity: 1;
                        bottom:29px;
                    }
                }
            }
            &:hover {
                box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.10);
                .lire-la-suite {
                    opacity: 1;
                }
            }
        }
    }
}



















.btn-01 {
    background: $touche_de_couleur;
    padding:0!important;
    margin-left:0!important;
    &:hover, &:focus {
        background: darken($touche_de_couleur, 15%);
        .icon span {transform:translate3d(1px,0,0);}
    }
    p {
        color:#fff;
        font-weight: $bold;
        font-size: 16.5px;
        padding:16px 90px 16px 30px;
        display: inline-block;
        user-select:none;
        text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        @include brk($m) {
            padding:16px 60px 16px 20px;
            font-size: 15px;
        }
    }
    .icon {
        font-size: 30px;
        color:#fff;
        width:52px;
        text-align: center;
        font-weight: $normal;
        height:34px;
        padding-top:2px;
        position: absolute;
        top:13px;
        line-height: 1;
        right:6px;
        border-left:1px solid rgba(255,255,255,0.3);
        span {
            transition: transform 0.5s;
            display: inline-block;
            line-height: 1;
            transform: translateY(-1px);
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
        @include brk($m) {
            width: 40px;
            right: 0;
        }
    }
    &:after {
        display: none!important;
    }
    @include brk($m) {
        width: 100%;
    }
}












#nav_ariane {
    animation: fade 2s;
    margin-bottom: 10px;
    >ul li:not(.current-menu-ancestor)>a, 
    >ul li:not(.current-menu-ancestor)>ul>li, 
    >ul li:not(.current-menu-ancestor)>ul,
    >ul>li>ul>li>ul>li:not(.current-menu-item)
    {
        display: none;
    }
    >ul li.current-menu-item>a {
        display:inline-block;
        color: $touche_de_couleur;
    }
    ul {
        display: inline-block;
    }
    >ul {
    }
    ul>li a {
        font-size: 10px;
        font-weight:$bold;
        letter-spacing:2px;
        text-transform: uppercase;
        animation: fade 2.5s;
        font-size: 10.5px;
        padding: 10px 0;
        text-transform: uppercase;
        &:hover {
            text-decoration: underline;
        }
    }
    >ul>li a {
        color:#000000;
        &:after {
            content:"›";
            margin-left: 6px;
            display: inline-block;
        }
    }
    >ul>li>ul>li>a:after {display:none}
    ul>li>ul>li>a {
        margin-left: 4px;
    }
    p {
        // display: none;
        animation: fade 2s;
        padding: 45px 0 40px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight:$black;
        span.sep {
            margin: 0 6px;
        }
        span.child {
            color: $touche_de_couleur;
        }
    }
}












.page-a .banner.etapes,
.page-b .banner.etapes {
    padding: 100px 0  50px !important;
}


.banner.etapes {
    padding-top:125px;
    padding-bottom:125px;
    // z-index: -1;
    margin-top: -5px;
    background:#fff;
    @include brk($t) {
        padding:75px 0 50px;
    }
    @include brk($tp) {
        text-align: center ;
    }
    @include brk($m) {
        // display: none;
    }
    .btn-01 {
        position: absolute;
        top: 20px;
        right: 0;
        background:#1599d6;
        border-radius:6px;
        height:63px!important;
        @include brk($td) {
            position: relative;
            right: auto;
            top: auto;
            margin-top: 50px;
            margin-bottom: 20px;
        }
        &:hover {
            background:linear-gradient( 0deg, rgb(87,182,66) 0%, rgb(141,194,62) 100%)!important;
        }
        p {
            padding-top:19px;
            color:#fff;
            cursor: pointer;
        }
    }
    ul {
        width:112%;
        margin-left: -6%;
        position: relative;
        display: inline-block;
        &:before {
            content:"";
            position: absolute;
            top:46px;
            bottom:0;
            height:50px;
            width:100%;
            left:0px;
            opacity: 1;
            background: url('../assets/images/ombre-etapes.png');
            background-size:contain;
            background-position:50% 50%;
            animation: fade 2s;
            @include brk($t) {
                display: none;
            }
        }
        @include brk($t) {
            width:100%;
            margin-left: 0%;
            &:before {
                top:-200px;
                z-index: 90;
                width: 160%;
                opacity: 0.8;
                margin-left: -30%;
                background-size:contain;
            }
        }
        @include brk($m) {
            &:before {
                display: none;
            }
        }
    }
    li {
        width:20%;
        display: inline-block;
        float: left;
        @for $i from 1 through 10 {
            &:nth-child(#{$i})  { 
                animation-delay: ((0.1s * $i) - 0.05s);
            }
        }
        @include brk($t) {
            width:100%;
        }
        a.inside {
            cursor:default;
            &[href]:not([href=""]):hover {
                p.texte {text-decoration: underline;}
                cursor:pointer;
            };
        }
        .icons-container {
            position: relative;
            height:150px;
            width: 90px;
            margin: 0 auto;
            .small-icon {
                position: absolute;
                top:0;
                right:-18px;
                font-size: 14px;
                font-weight: $black;
                width: 47px;
                height: 47px;
                background: #dcf0f9;
                color:#0e4285;
                border-radius: 50%;
                text-align:center;
                padding-top:16px;
                display: none !important;
            }
            .big-icon {
                position: absolute;
                top:21px;
                left: 0;
                height:90px;
                width:90px;
            }
            @include brk($t) {
                margin:0;
                float: left;
                height:100px;
                width: 65px;
                .big-icon {
                    height:65px;
                    width:65px;
                    top:16px;
                }
                .small-icon {
                    height:35px;
                    width:35px;   
                    padding-top:9px;    
                }
            }
            @include brk($m) {
                .big-icon {
                    height:60px;
                    width:60px;
                    top:29px;
                }
            }
        }
        .texte-container {
            .texte {
                font-size: 17px;
                font-weight: $semi-bold;
                text-align: center;
                line-height: 1.4;
                color: #0e4285;
                padding: 0 18px;
            }
            @include brk($t) {
                width: calc(100% - 120px);
                float: right;
                .texte {
                    text-align: left;
                    padding:34px 0 34px;
                    br {display:none;}
                    border-bottom: 1px solid transparentize(#000, 0.9);
                }
            }
            @include brk($m) {
                width: calc(100% - 85px);
                .texte {
                }
            }
        }
        &:last-child .texte {
            border:none!important
        }
    }
}


