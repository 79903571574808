

*[when-visible="fade"], 
*[when-visible="fade_late"],
*[when-visible="scale"],
*[when-visible="up"],
*[when-visible="down"],
*[when-visible="left"],
*[when-visible="right"],
*[when-visible="width"],
*[when-visible="discover"],
*[when-visible="custom"] {
    opacity: 0;
    animation-fill-mode: both;
}

.not-visible {
    opacity:0;
}


.fade-in-when-visible {
    transition: opacity 1s;
    opacity:1;
}






@keyframes wv_scale {
    0% {transform:scale(0.75);}
    100% {transform:scale(1);}
}
.scale-in-when-visible {
    animation-duration: 0.9s;
    animation-name: wv_scale;

    transition: opacity 1s;
    opacity:1;
}




@keyframes wv_up {
    0% {transform:translate3d(0,10px, 0)}
    100% { transform:translate3d(0, 0px, 0) scale(0.999);}
}
.up-when-visible {
    animation-duration: 2s;
    animation-name: wv_up;

    transition: opacity 1s;
    opacity:1;
}



@keyframes wv_down {
    0% {transform:translate3d(0,-10px, 0)}
    100% { transform:translate3d(0, 0px, 0) scale(0.999);}
}
.down-when-visible {
    animation-duration: 2s;
    animation-name: wv_down;

    transition: opacity 1s;
    opacity:1;
}



@keyframes wv_left {
    0% {transform:translate3d(10px,0px, 0)}
    100% { transform:translate3d(0, 0px, 0) scale(0.999);}
}
.left-when-visible {
    animation-duration: 2s;
    animation-name: wv_left;

    transition: opacity 1s;
    opacity:1;
}



@keyframes wv_right {
    0% {transform:translate3d(-10px,0px, 0)}
    100% { transform:translate3d(0, 0px, 0) scale(0.999);}
}
.right-when-visible {
    animation-duration: 2s;
    animation-name: wv_right;

    transition: opacity 1s;
    opacity:1;
}



@keyframes wv_width {
    0% {width:0}
    100% { width:100%;}
}
.width-when-visible {
    animation-duration: 1s;
    animation-name: wv_width;

    transition: opacity 1s;
    opacity:1;
}


@keyframes wv_discover {
    0% {transform: translate3d(-100%,0,0); opacity: 1}
    100% {transform: translate3d(0%,0,0); opacity: 1}
}
.discover-when-visible {
    animation-duration: 1s;
    animation-name: wv_discover;
}


