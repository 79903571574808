


.post.single {
	background:#fff;
	animation: fade 2s;
}






.post.single .banner.top {
	// background:#125c80;
	height:200px;
	padding-top:33px;
	animation: fade 1s;

	// margin-top:$header-height;
	@include brk($t) {
		// margin-top:$header-height-tablet;
	}
	.content {
		position: relative;
	}
	.icon {
		position: absolute;
		width: 80px;
		height: 80px;
		top:0;
		left:0;
		display: none;
		.st0{opacity:0.15;fill:#1499D6;}
		.st1{fill:#1499D6;}
		.st2{fill:none;stroke:#FFFFFF;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	}
	.sur-titre {
		color:#0185b6 !important;
	}
	.titre {
		color: #fff;
		padding-top:22px;
		font-size: 22.5px;
		font-weight: $bold;
		padding-left: 108px;
		color:#0185b6 !important;
		display: none;
	}
}




.post.single .banner.medium {
	background:#f4f4f4;
	padding: 0 0 80px;
	.content {
		margin-top: -138px;

	}
	.top-image {
		height:383px;
		position: relative;
		@include brk($t) {
			height:320px;
		}
		@include brk($tp) {
			height:260px;
		}
		@include brk($m) {
			height:217px;
		}
	}

	.sub-banner {
		height:60px;
		background:#f4f4f4;
		position: absolute;
		bottom: 0;
		left: 0;
		.btn-retour {
			height:100%;
			background: $touche_de_couleur;
			float: left;
			position: relative;
			animation: fade 1.2s;
			display: flex;
			align-items: center;
			.icon {
				position: absolute;
				height:14px;
				width:14px;
				left:32px;
				top:23px;
				transform: rotate(180deg);
					transition: transform 0.3s;
				.st0 {
					fill:#fff;
				}
				@include brk($m) {
					top: 24px;
				}
			}
			p {
				color:#fff;
				text-transform: uppercase;
				padding-left: 81px;
				padding-right: 40px;
				font-size: 10px;
				font-weight: $bold;
				letter-spacing: 1.5px;
				line-height: 1;
			}
			@include brk($m) {
				width: 79px;
				p {display:none;}
			}
			&:hover {
				background: darken(#0d1221, 5%);
				.icon {
					transform: rotate(180deg) translate3d(3px,0,0);
				}
			}
		}
		.bloc-categorie {
			height:100%;
			min-width: 160px;
			float: right;
			border-left: 1px solid rgba(0,0,0,0.05);
			padding:31px 48px 0 66px;
			position: relative;
			background:#efefef;
			display: none !important;
			@include brk($m) {
			}
			.icon-categorie {
				animation: fade 1s;
				width:15px;
				height:15px;
				display:inline-block;
				position: absolute;
				margin-top: 2px;
				left:36px;
				top:33px;
				.st0{
					fill:none;
					stroke:$touche_de_couleur;
					stroke-width:7.2367;
					stroke-miterlimit:10;
				}
				rect {
					fill:$touche_de_couleur;
				}
			}
			p {
				color:$touche_de_couleur;
				user-select:none;
				font-size: 10px;
				animation: fade 1s;
	            text-transform: uppercase;
	            font-weight: $bold;
	            padding:5px 0 15px;
	            letter-spacing: 1px;
	            // transition: color 1s;
			}
		}
	}

	.module-partage {
		display:inline-block;
		height: 200px;
		width:50px;
		animation: fade 2s;
		text-align:right;
		position: absolute;
		top:75px;
		left:82px;
		@include brk($t) {
			top:auto;
			left: auto;
			position: relative;
			width:250px;
			margin-top: 20px;
			text-align:left;
			height: 65px;
		}
		.icon {
			height: 46px;
			width: 46px;
			margin-bottom: 18px;
			border:1px solid rgba(0,0,0,0.15);
			cursor: pointer;
			// background:rgba(0,0,0,0.15);
			// transition: background 0.3s, border 0.5s;
			border-radius:50%;
			@include brk($t) {
				margin-right: 13px;
			}
			&:hover {
				border:1px solid rgba(255,255,255,1);
				background: darken(#0d1221, 5%);
				svg {
					// transform:scale(1.15);
				}
				.st0 {fill:#fff;}
			}
			svg {transform:scale(1.0);}
			.st0 {
				// transition: fill 1s;
				fill:$touche_de_couleur;
			}
		}
	}

	.infos-banner {
		min-height:500px;
		background:#ffffff;
		position: relative;
		padding: 70px 8% 75px 20%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		@include brk($t) {
			padding: 50px 5.5% 75px 5.5%;
		}
		.titre-post {
			font-size: 35px;
			color:#104b99;
			font-weight: $semi-bold;
			line-height: 1.4;
			padding-bottom:40px;
			animation: fade 1s;
			@include brk($t) {
				padding-bottom:30px;
			}
			@include brk($tp) {
				font-size: 30px;
			}
			@include brk($m) {
				font-size: 23px;
			}
		}
		.flex-image {
			height:344px;
			margin-bottom: 35px;
			@include brk($m) {
				height:140px;
			}
			.image {
				animation: fade 1s;
			}
		}
		.flex-chapeau {
			margin-bottom: 35px;
			animation: fade 1.5s;
			p {
				font-size: 17.5px;
				font-weight: $bold;
				letter-spacing: 0.5px;
				color:#787878;
				@include brk($m) {
					font-size: 16px;
				}

			}
		}
		.flex-texte {
			animation: fade 1.5s;
			a {
				text-decoration: underline!important;
				display: inline;
				&:hover {
					color:darken(#787878, 20%)!important
				}
			}
			li, p, a, span {
				color:darken(#787878, 10%);
			}
			h2, h3, h4 {
				color:$touche_de_couleur;
				font-weight: $bold;
				padding-top:10px;
			}
			li, a, p {
				strong, b {
					font-weight: $normal;
				}
				// padding-top: 0;
			}
			h2 {
				padding-top:20px;
				padding-bottom:10px;
			}
		}
		.tags-container {
			margin-top: 30px;
			li {
				display: inline-block;
				border:1px solid rgba(0,0,0,0.3);
				border-radius:6px;
				margin-right: 17px;
				margin-bottom: 17px;
				transition: border 0.5s;
				&:hover {
					border:1px solid rgba(0,0,0,0.5);
					p {
						color:darken(#aaaaaa, 25%)
					}
				}
				a {
					position: relative;
					display: inline-block;
					padding:17px 19px 15px;
				}
			}
			.icon-tag {
				width:14px;
				height:14px;
				display:inline-block;
				position: absolute;
				top:14px;
				left:16px;
				.st0{fill:none;}
				.st1{fill:#aaaaaa;}
			}
			p {
				color:#aaaaaa;
				font-size: 10px;
				transition: color 0.5s;
				padding-left:25px;
				line-height: 1;
				letter-spacing: 0.7px;
				text-transform: uppercase;
			}
		}
	}
}






.post.single .banner.bottom {
	min-height:317px;
	background:#f4f4f4;
	padding:60px 0 65px;
	.titre-section {
		color:#104b99;
		font-size: 25px;
		font-weight: $bold;
		text-align:center;
		margin-bottom: 58px;
	}
	form {
        z-index: 80;
        height:82px;
        width:100%;
        display: block;
        margin: 45px auto 0;
        max-width:824px;
        border-radius:6px;
        // overflow: hidden;
        animation: fade 1.5s;
        position: relative;
        box-shadow: 0px 3px 38px 0px rgba(39, 51, 66, 0.46);
        @include brk($tp) {
            height:auto;
        }
        // &:before {
        //     content:"";
        //     display: inline-block;
        //     width:24px;
        //     height:18px;
        //     background: url('../assets/images/icon-envelop.svg');
        //     background-position:50% 50%;
        //     background-size:contain;
        //     position: absolute;
        //     left:35px;
        //     top:33px;
        //     z-index: 100;
        //     @include brk($tp) {display:none}
        // }
        input[type="text"] {
            float: left;
            height:100%;
            width:60%;
            background: #fff;
            padding-left:44px;
            padding-top: 2px;
            @include brk($t) {
                width: 58%;
            }
            @include brk($tp) {
                border-radius:8px;
                height:82px;
                width:100%;
                margin-bottom: 6px;
                padding-left:0px;
                text-align: center;
            }
        }
        button[type="submit"] {
            float: right;
            height:100%;
            width:40%;
            background:#dd5953;
            color:#fff;
            &:hover, &:focus {
                background:darken(#dd5953, 15%);
            }
            @include brk($t) {
                width: 42%;
            }
            @include brk($tp) {
                border-radius:8px;
                height:82px;
                width:100%;
            }
            p {
                padding-top:27px;
                text-align: center;
            }
            .icon {
                top:20px;
                padding-top:6px;
                height:45px;
                width: 45px;
            }
        }
        .tooltip {
            position: absolute;
            top:-44px;
            left:44px !important;
            @include brk($tp){ 
                left:calc(50% - 117px) !important;
            }
        }
    }

	/**************  PLACEHOLDER ADJUST   ***************/

	$placeholder_FONT_SIZE: 16px;
	$placeholder_FONT_SIZE-tablet: 16px;
	$placeholder_FONT_SIZE-mobile: 16px;
	$placeholder_FONT_WEIGHT: $normal;
	$placeholder_TEXT_ALIGN: left;
	$placeholder_COLOR: #868686;
	$placeholder_TRANSITION: opacity 0.3s;
	$placeholder_FOCUS_OPACITY: 0.35;


	::-webkit-input-placeholder { /* WebKit browsers */
	    color: $placeholder_COLOR;
	    font-weight: $placeholder_FONT_WEIGHT;
	    text-align: $placeholder_TEXT_ALIGN;
	    font-size: $placeholder_FONT_SIZE;
	    transition: $placeholder_TRANSITION;
	    @include brk ($t){
	        font-size:$placeholder_FONT_SIZE-tablet;
	    }
	    @include brk ($m){
	        font-size:$placeholder_FONT_SIZE-mobile;
	    }
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	   color: $placeholder_COLOR;
	   font-weight: $placeholder_FONT_WEIGHT;
	   text-align: $placeholder_TEXT_ALIGN;
	   font-size: $placeholder_FONT_SIZE;
	   transition: $placeholder_TRANSITION;
	   opacity:  1;
	   @include brk ($t){
	        font-size:$placeholder_FONT_SIZE-tablet;
	   }
	   @include brk ($m){
	        font-size:$placeholder_FONT_SIZE-mobile;
	   }
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
	   color: $placeholder_COLOR;
	   font-weight: $placeholder_FONT_WEIGHT;
	   text-align: $placeholder_TEXT_ALIGN;
	   font-size: $placeholder_FONT_SIZE;
	   transition: $placeholder_TRANSITION;
	   opacity:  1;
	   @include brk ($t){
	        font-size:$placeholder_FONT_SIZE-tablet;
	   }
	   @include brk ($m){
	        font-size:$placeholder_FONT_SIZE-mobile;
	   }

	}
	:-ms-input-placeholder { /* Internet Explorer 10+ */
	   color: $placeholder_COLOR;
	   font-weight: $placeholder_FONT_WEIGHT;
	   text-align: $placeholder_TEXT_ALIGN;
	   font-size: $placeholder_FONT_SIZE;
	   transition: $placeholder_TRANSITION;
	   @include brk ($t){
	        font-size:$placeholder_FONT_SIZE-tablet;
	   }
	   @include brk ($m){
	        font-size:$placeholder_FONT_SIZE-mobile;
	   }
	}

	input:focus, textarea:focus {
	    &::-webkit-input-placeholder {
	        opacity: $placeholder_FOCUS_OPACITY;
	    }
	    &:-moz-placeholder {
	        opacity: $placeholder_FOCUS_OPACITY;
	    }
	    &::-moz-placeholder {
	        opacity: $placeholder_FOCUS_OPACITY;
	    }
	    &:-ms-input-placeholder {
	        opacity: $placeholder_FOCUS_OPACITY;
	    }
	}

}