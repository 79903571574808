$ft: $t + 0px;
$fm: $m + 0px;



footer {
    // overflow-y: hidden;
    z-index: 110;
    position: relative;
    background: $footer-color;
    // box-shadow: 0px 0px 30px 0px transparentize(#000, 0.9);
    @include brk($tp) {
        text-align: center;
    }
    &.page {
        border-top: 1px solid transparentize(#eee, 0);
    }
    #footer_top {
        background:#fff;
        padding-top:50px;
        padding-bottom:30px;
        @extend .clearfix;
        @include brk($ft) {
            padding-bottom:40px;
        }
        @include brk($fm) {
            padding-top:30px;
            padding-bottom:30px;
        }
        p, a {
            color:$white;
            font-size: 15px;
        }
    }
    #footer_bottom {
        padding-top: 8px;
        height:62px;
        @include brk($tp) {
            height:auto;
        }
        @include brk($fm) {
            padding-top: 13px;
            padding-bottom: 8px;
        }
        
    }
}





footer #footer_top .texte-container {
    padding-bottom:58px;
    @include brk($ft) {
        padding-bottom:35px;
    }
    @include brk($fm) {
        padding-bottom:35px;
    }
    p {
        font-size: 25px;
        font-weight: $normal;
        color:#125c80;
        &.bold {
            font-weight: $bold;
            color:$touche_de_couleur;
        }
        @include brk($fm) {
            display: inline;
            font-size: 16.3px;}
    }
}





footer #footer_top .logo-container {
    width:100px;
    height: 100px;
    position: relative;
    float: left;
    display: inline-block;
    margin-right: 50px;
    @include brk($tp) {display:none}
    .logo {
        position: absolute;
        top:0px;
        left:-5px;
        width:100px;
        .st0{fill:#FFFFFF;}
        .st1{fill:#0191CD;}
        .st2{fill:#55A3D1;}
        .st3{fill:#376A89;}
        .st4{fill:#095C80;}
        .st5{fill:#95BE20;}
        .st6{fill:#B5CCE7;}
        .st7{fill:#A4C6E7;}
        .st8{fill:#52A1D0;}
    }
}







footer #footer_top .appel-gratuit-container {
    float: left;
    width: calc(100% - 500px);
    padding-top: 15px;
    display: inline-block;
    @include brk($ft) {
        width: calc(100% - 400px)
    }
    @include brk($tp) {
        width:100%;
        padding-top: 0;
    }
    h5 {
        display: inline-block;
        font-weight: $bold;
        font-size: 16px;
        margin-right: 20px;
        transform: translateY(-1px);
        @include brk($m) {
            margin-bottom: 10px;
        }
    }
    ul {
        display: inline-block;
        li {
            display: inline-block;
            margin-right: 0px;
        }
    }
    a {
        padding-top:20px;
        padding-bottom:15px;
        font-size: 16px;
        font-weight: $bold;
        color:#125c80;
        line-height: 1;
        &:hover {
            text-decoration: underline;
        }
        span {
            @include brk($m) {
                display: block;
                padding: 5px 0;
            }
        }
        @include brk($m) {
            padding-top:3px;
            padding-bottom:3px;
        }
    }
    .numero {
        margin-right:5px;
        padding-left: 5px;
        font-weight: $black;
        color:#0090c6;
        line-height: 1;
    }
}




footer .social-container {
    float: right;
    margin-top: 20px;
    @include brk($tp) {
        float:none;
        display: inline-block;
        margin-top: 30px;
    }
    .icon-container {
        .icon {
            width:40px;
            height:40px;
        }
        .st0 {fill:#0090c6; transition: fill 0s}
        .st1 {fill:#fff!important; transition: fill 0s}
        &:hover, &:focus {
            .st0 {fill:darken(#0d1221, 5%)}
            .st1 {fill:lighten(#fff, 20%);}
        }
    }
}


footer #footer_bottom .copyright-line {
    display:inline-block;
    text-align:center;
    width:100%;
    p, a, span {
        color:transparentize(#fff,0.1);
        // text-transform: uppercase;
        font-size: 11px;
        letter-spacing:0.6px;
        transition: color 0.6s;
    }
    a {
        padding: 15px 10px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        animation: fade 2s;
        &:hover, &:focus {
            color:transparentize(#fff,0);
            text-decoration: underline;
        }
        &:first-child {
            border-bottom:none;
            &:before {display:none;}
        }
        &:before {
            content:"";
            display: inline-block;
            position: absolute;
            top:13px;
            left:-4px;
            width:1px;
            height:15px;
            background:rgba(255,255,255,0.15);
            @include brk($fm){display:none;}
        }
        @include brk($fm){
            padding: 0px 15px;
            letter-spacing: 0;
            padding: 5px 5px;
        }
    }
    p {
        display:inline;
    }
}




footer #footer_bottom #signature_SFD {
    // float: right;
    padding-bottom: 8px;
    padding-left: 8px;
    position: relative;
    @include brk($tp) {
        padding-top: 5px;
        margin-bottom: 9px;
    }
    @include brk($fm) {
        float: none;
        margin-top: 8px;
        clear: both;
        display: block;
        margin-bottom: 0px;
    }
    &:hover, &:focus {
        p {color:transparentize(#fff,0)!important}
        .icon path {fill:transparentize(#fff,0)}
    }
    &:before {
        top:17px;
    }
    p {
        font-size: 11px;
        display: inline-block;
        color:transparentize(#fff,0.1);
        margin-right: 7px;
        transition: color 0.5s;
        cursor: pointer;
    }
    .icon {
        position: absolute;
        top:6px;
        right:-14px;
        height:25px;
        width:25px;
        margin-top: 6px;
        path {
            fill:transparentize(#fff,0.1);
            transition: fill 0.5s;
        }
        @include brk($tp) {
            top:-3px;
        }
        @include brk($fm){
            top:-4px;
            right: calc(50% - 86px);
        }
    }
}

